<template>
  <section>
    <div class="bg-white p-4 rounded-md w-full mt-2">
      <div class="w-full lg:flex">
        <div class="w-full lg:w-5/12">
          <div class="flex gap-2 items-center">
            <i class="pi pi-copy text-blue-800 text-xl"></i>
            <div>
              <p class="font-bold text-blue-800 text-xl">Orden de venta</p>
              <p class="font-bold text-blue-800 text-xl">{{ $route.params.id }}</p>
            </div>
          </div>
        </div>
        <div class="w-full lg:w-7/12 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
          <div class="grid-cols-1 grid gap-1 text-center text-xs">
            <p class="text-gray-600">Estado</p>
            <p class="font-bold text-blue-800">{{ ordenesStore._orden.DocStatus === 0 ? 'Abierta' : (ordenesStore._orden.DocStatus === 1 ? 'Cerrada' : 'Cancelada') }}</p>
          </div>
          <div class="grid-cols-1 grid gap-1 text-center text-xs">
            <p class="text-gray-600">Fecha Cont./Doc</p>
            <p class="font-bold text-blue-800">{{ dayjs(ordenesStore._orden.FechaDocumento).format('YYYY/MM/DD') }}</p>
          </div>
          <div class="grid-cols-1 grid gap-1 text-center text-xs">
            <p class="text-gray-600">Cliente</p>
            <p class="font-bold text-blue-800">{{ ordenesStore._orden.Cliente.CardName }}</p>
          </div>
          <div class="grid-cols-1 grid gap-1 text-center text-xs">
            <p class="text-gray-600">Bodega</p>
            <p class="font-bold text-blue-800">{{ ordenesStore._orden.U_PHR_Bodega ? ordenesStore._orden.U_PHR_Bodega : 'N/A' }}</p>
            <p class="font-bold text-blue-800">{{ ordenesStore._orden.Bodega ? ordenesStore._orden.Bodega.WhsName : 'N/A' }}</p>
          </div>
          <div class="grid-cols-1 grid gap-1 text-center text-xs">
            <p class="text-gray-600">Paciente</p>
            <p class="font-bold text-blue-800">{{ ordenesStore._orden.U_ACS_TpoIdentf + ' - ' + ordenesStore._orden.U_PHR_NumDcto }}</p>
            <p class="font-bold text-blue-800">{{ ordenesStore._orden.Paciente.U_ACS_NmbPct }}</p>
          </div>
        </div>
      </div>
      <div class="w-full shadow-md rounded-md p-2">
        <div class="w-full flex text-blue-800 gap-2">
          <button @click="showUdf = !showUdf"><i :class="showUdf ? 'pi-chevron-down' : 'pi-chevron-right'" class="pi text-xs"></i></button>
          <p class="font-bold">UDF</p>
        </div>
        <div v-if="showUdf" class="w-full grid grid-cols-1 md:grid-cols-4 lg:grid-cols-10 gap-4 mt-2">
          <div v-tooltip="`${ordenesStore._orden.DiagPpal.DesCie}`" class="grid-cols-1 grid gap-1 text-center text-xs">
            <p class="text-gray-600">Diag. Principal:</p>
            <p class="font-bold text-gray-600">{{ ordenesStore._orden.DiagPpal ? ordenesStore._orden.DiagPpal.CdCie : 'N/A' }}</p>
          </div>
          <div class="grid-cols-1 grid gap-1 text-center text-xs">
            <p class="text-gray-600">No. Autorización:</p>
            <p class="font-bold text-gray-600">{{ ordenesStore._udf.numero_autorizacion }}</p>
          </div>
          <div class="grid-cols-1 grid gap-1 text-center text-xs">
            <p class="text-gray-600">Siniestro:</p>
            <p style="overflow-wrap: anywhere;" class="font-bold text-gray-600">{{ ordenesStore._udf.siniestro }}</p>
          </div>
        </div>
      </div>
      <div class="w-full mb-2 mt-2 shadow-md rounded-md p-2 flex flex-wrap lg:flex-auto gap-2">
        <Button @click="$router.push({ name: `ut-segupharma.ventas.ov.ver.tabla`, params: {id: $route.params.id} })" class="p-button-raised p-button-text p-button-success" icon="pi pi-home" />
        <Button @click="$router.push({ name: `ut-segupharma.ventas.ov.ver.mapa-relaciones`, params: {id: $route.params.id} })" icon="pi pi-sitemap" class="p-button-raised p-button-text" label="Mapa de relaciones" />
        <Button @click="$router.push({ name: `ut-segupharma.ventas.ov.ver.historial`, params: {id: $route.params.id} })" icon="pi pi-undo" class="p-button-raised p-button-warning p-button-text" label="Historial del documento" />
        <Button @click="$router.push({ name: `ut-segupharma.ventas.ov.ver.devolucion`, params: {id: $route.params.id} })"  v-if="medicamentosStore._medicamentos_tabla.some(a => a.LineStatus === 1) && ordenesStore._orden.DocStatus !== 2 && $can('ut-segupharma.ventas.ov.ver.devolucion')" icon="pi pi-clone" class="p-button-raised p-button-danger p-button-text" label="Devolución" />
        <Button @click="$router.push({name: 'ut-segupharma.ventas.ov.editar', params: {id: ordenesStore._orden.Id}})" v-if="ordenesStore._orden.DocStatus !== 2 && $can('ut-segupharma.ventas.ov.editar')" icon="pi pi-pencil" class="p-button-raised p-button-help p-button-text" label="Editar" />
        <Button @click="cancelarOrden(ordenesStore._orden.Id)" icon="pi pi-times" v-if="ordenesStore._orden.DocStatus !== 2 && !pagoSincronizado && $can('ut-segupharma.ventas.ov.ver.cancelar')" class="p-button-raised p-button-danger p-button-text" label="Cancelar orden" />
        <Button @click="DescargarPdf(ordenesStore._orden.Id)" icon="pi pi-print" class="p-button-raised p-button-danger p-button-text" label="Imprimir orden" />
      </div>
      <router-view></router-view>
    </div>
  </section>
</template>
<script>
  import { onMounted, computed, ref } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { useStore } from '@/store'
  import { useOrdenesStore } from '../../../stores/ordenes.store'
  import { useMedicamentosStore } from '../../../stores/medicamentos.store'
  import Orden from '../../../services/orden.service'
  import dayjs from 'dayjs'
  import Cookies from 'js-cookie'
  import axios from 'axios'
  import Swal from 'sweetalert2'
  export default {
    name: 'verOrdenVenta',
    setup () {
      // Servicios
      const _Orden = ref(new Orden())
      // Referencias
      const showUdf = ref(true)
      const showMedicamentos = ref(true)
      const showHistorialDocumento = ref(true)
      const expandedRows = ref(null)
      const route = useRoute()
      const router = useRouter()
      const dataMedicamento = ref({})
      const columnasTabla = ref([])
      const listadoMedicamentos = ref([])
      const displayModalInfoMedicamento = ref(false)
      const displayHistorialDocumento = ref(false)
      const lotesEntregar = ref([])
      const vista = ref('principal')
      const displayModalMedicamentoPendiente = ref(false)
      const displayModalMedicamentosPendientesListado = ref(false)
      const displayModalImprimirOrden = ref(false)
      const lineasImprimir = ref([])
      const lotePendienteSeleccionado = ref({})
      const events1 = ref([
          { status: 'Creación de la orden de venta', descripcion: 'Se genera la creación con 4 pendientes', date: '15/10/2020 10:30', icon: 'pi pi-shopping-cart', color: '#9C27B0', image: 'game-controller.jpg' },
          { status: 'Entrega', descripcion: 'Se genera la entrega con 2 pendientes', date: '15/10/2020 14:00', icon: 'pi pi-cog', color: '#673AB7' },
          { status: 'Entrega', descripcion: 'Se genera la entrega con 1 pendientes', date: '15/10/2020 16:15', icon: 'pi pi-shopping-cart', color: '#FF9800' },
          { status: 'Entrega', descripcion: 'Se genera la entrega total', date: '16/10/2020 10:00', icon: 'pi pi-check', color: '#607D8B' }
      ])
      // Stores
      const storePersona = useStore()
      const ordenesStore = useOrdenesStore()
      const medicamentosStore = useMedicamentosStore()
      // Computadas
      const persona = storePersona.getters['auth/getPeople']
      const pagoSincronizado = computed(() => ordenesStore._pago_sincronizado_sap)
      // Métodos
      const cancelarOrden = (id) => {
        _Orden.value.validarOvSync(id).then(({ data }) => {
          if (data) {
            Swal.fire(
              'Cancelar Ov!',
              'No puede cancelar esta orden. Ya tiene una entrega en SAP!',
              'warning'
            )
          } else {
            Swal.fire({
            title: `<p style="line-height: initial">Estas seguro de cancelar la orden ${route.params.id} ?</p>`,
            text: 'Al realizar esta acción todos los lotes de los artículos que se encuentran entregados regresaran a stock',
            showCancelButton: true,
            showDenyButton: true,
            showConfirmButton: false,
            denyButtonText: 'Cancelar Orden',
            cancelButtonText: 'Cerrar',
            icon: 'warning'
          }).then((result) => {
            if (result.isDenied) {
              ordenesStore.cancelarOrden(route.params.id).then((data) => {
                Swal.fire(
                  'Cancelada!',
                  'La orden se ah cancelado con exito!',
                  'success'
                ).then(() => {
                  router.push({ name: 'ut-segupharma.ventas.ov.listado' })
                })
              })
            }
          })
          }
        })
      }
      const DescargarPdf = (id) => {
        const url = `${process.env.VUE_APP_MICROSERVICE_API_UT_SEGUPHARMA_ORDENES_VENTA}/ov/generar-pdf/${id}`
        const token = JSON.parse(Cookies.get('user')).token
        const res = axios.getUri({ url, params: { token: `Bearer ${token}` } })
        window.open(res, '_blank')
      }
      onMounted(() => {
        ordenesStore.findPagoRecibido(route.params.id)
      })
      return {
        dayjs,
        showUdf,
        expandedRows,
        dataMedicamento,
        displayModalInfoMedicamento,
        columnasTabla,
        listadoMedicamentos,
        showMedicamentos,
        showHistorialDocumento,
        events1,
        displayHistorialDocumento,
        persona,
        lotesEntregar,
        vista,
        cancelarOrden,
        DescargarPdf,
        displayModalMedicamentoPendiente,
        displayModalMedicamentosPendientesListado,
        displayModalImprimirOrden,
        lineasImprimir,
        lotePendienteSeleccionado,
        pagoSincronizado,
        ordenesStore,
        medicamentosStore
      }
    }
  }
</script>
